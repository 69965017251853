const initialState = {
  loading: false,
  campaignClaimIsActive: false,
  campaignClaimed: false,
  snapshotClaimIsActive: false,
  snapshotClaimed: false,
  snapshotClaimTokenID: 0,
  merkleProof: [],
  calculatedRoot: "",
  merkleRoot: "",
  pjBalance: 0,
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        campaignClaimIsActive: action.payload.campaignClaimIsActive,
        campaignClaimed: action.payload.campaignClaimed,
        snapshotClaimIsActive: action.payload.snapshotClaimIsActive,
        snapshotClaimed: action.payload.snapshotClaimed,
        snapshotClaimTokenID: action.payload.snapshotClaimTokenID,
        merkleProof: action.payload.merkleProof,
        calculatedRoot: action.payload.calculatedRoot,
        merkleRoot: action.payload.merkleRoot,
        pjBalance: action.payload.pjBalance,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
