// log
import store from "../store";
import Web3 from "web3";
const { MerkleTree } = require('merkletreejs')


const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = (blockchainAccount) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());

    try {
      const merkleLeavesResponse = await fetch("/config/merkletree.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          cache: "no-cache"
        },
      });
      var options = {
        hashLeaves: false,
        sortLeaves: true,
        sortPairs: true,
        duplicateOdd: false,
        isBitcoinTree: false
      }
      const merkleLeaves = await merkleLeavesResponse.json();

      const hashedLeaves = merkleLeaves.map(x => Web3.utils.keccak256(
        Web3.utils.encodePacked(
            {value: Web3.utils.toChecksumAddress(x["address"]), type: 'address'},
            {value: x["claimTokenID"], type: 'uint256'}
          )
      ));
      let snapshotClaimTokenID = 0;
      for(let i = 0;i < merkleLeaves.length;i++) {
        if(Web3.utils.toChecksumAddress(merkleLeaves[i]["address"]) == Web3.utils.toChecksumAddress(blockchainAccount)) {
          snapshotClaimTokenID = merkleLeaves[i]["claimTokenID"]
        }
      }
      const tree = new MerkleTree(hashedLeaves, Web3.utils.keccak256, options);
      const calculatedRoot = tree.getRoot().toString('hex');
      const currentLeaf = Web3.utils.keccak256(Web3.utils.encodePacked(
            {value: Web3.utils.toChecksumAddress(blockchainAccount), type: 'address'},
            {value: snapshotClaimTokenID, type: 'uint256'}
          ));
      const currentProof = tree.getHexProof(currentLeaf.toString('hex'));
      const merkleProof = currentProof.map(x => x.toString('hex'));

      let rewardCampaignID = await store
        .getState()
        .blockchain.rewardsContract.methods.rewardCampaignID()
        .call();
      let TIER_1_MINIMUM = await store
        .getState()
        .blockchain.rewardsContract.methods.TIER_1_MINIMUM()
        .call();
      let TIER_2_MINIMUM = await store
        .getState()
        .blockchain.rewardsContract.methods.TIER_2_MINIMUM()
        .call();
      let TIER_3_MINIMUM = await store
        .getState()
        .blockchain.rewardsContract.methods.TIER_3_MINIMUM()
        .call();
      let TIER_4_MINIMUM = await store
        .getState()
        .blockchain.rewardsContract.methods.TIER_4_MINIMUM()
        .call();
      let merkleRoot = await store
        .getState()
        .blockchain.rewardsContract.methods.snapshotMerkleRoot()
        .call();

      let campaignClaimIsActive = await store
        .getState()
        .blockchain.rewardsContract.methods.campaignRewardClaimOpen()
        .call();
      let snapshotClaimIsActive = await store
        .getState()
        .blockchain.rewardsContract.methods.snapshotRewardClaimOpen()
        .call();

      let campaignClaimTokenID = 0;
      let campaignClaimed = false
      let pjBalance = 0;
      if(campaignClaimIsActive) {
        pjBalance = await store
          .getState()
          .blockchain.pixiejarsContract.methods.balanceOf(blockchainAccount)
          .call();
        pjBalance = parseInt(pjBalance);
        TIER_1_MINIMUM = parseInt(TIER_1_MINIMUM);
        TIER_2_MINIMUM = parseInt(TIER_2_MINIMUM);
        TIER_3_MINIMUM = parseInt(TIER_3_MINIMUM);
        TIER_4_MINIMUM = parseInt(TIER_4_MINIMUM);
        if(pjBalance >= TIER_4_MINIMUM) {
          campaignClaimTokenID = rewardCampaignID * 1000 + 4;
        } else if(pjBalance >= TIER_3_MINIMUM) {
          campaignClaimTokenID = rewardCampaignID * 1000 + 3;
        } else if(pjBalance >= TIER_2_MINIMUM) {
          campaignClaimTokenID = rewardCampaignID * 1000 + 2;
        } else if(pjBalance >= TIER_1_MINIMUM) {
          campaignClaimTokenID = rewardCampaignID * 1000 + 1;
        } 
        if(campaignClaimTokenID > 0) {
          let claimTokenBalance = await store
            .getState()
            .blockchain.rewardsContract.methods.balanceOf(blockchainAccount, campaignClaimTokenID)
            .call();
          campaignClaimed = (claimTokenBalance > 0);
        }
      }

      let snapshotClaimed = false;
      if(snapshotClaimIsActive) {
        if(snapshotClaimTokenID > 0) {
          let claimTokenBalance = await store
            .getState()
            .blockchain.rewardsContract.methods.balanceOf(blockchainAccount, snapshotClaimTokenID)
            .call();
          snapshotClaimed = (claimTokenBalance > 0);
        }
      }

        
      console.log(merkleLeaves);
      console.log("Calculated root: " + calculatedRoot);
      console.log("Current root: " + merkleRoot.toString('hex'));
      console.log("Current leaf: " + currentLeaf);
      console.log(merkleProof);
      console.log("Snapshot Claim Token: " + snapshotClaimTokenID);
      console.log((merkleRoot.toString('hex')) == ("0x" + calculatedRoot.toString('hex')));

      dispatch(
        fetchDataSuccess({
          campaignClaimIsActive,
          campaignClaimed,
          snapshotClaimIsActive, 
          snapshotClaimed,
          snapshotClaimTokenID,
          merkleProof,
          calculatedRoot,
          merkleRoot,
          pjBalance,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
